.cta {
  .section-inner {
    padding-top: $cta--padding-t__mobile;
    padding-bottom: $cta--padding-b__mobile;
  }
}

.cta-slogan {
  margin-bottom: $cta-slogan--padding-v__mobile;
}

@include media(">medium") {
  .cta {
    .section-inner {
      padding-top: $cta--padding-t__desktop;
      padding-bottom: $cta--padding-b__desktop;
    }
  }

  .cta-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cta-slogan {
    margin-bottom: $cta-slogan--padding-v__desktop;

    // .cta-split & {
    // 	margin-bottom: 0;
    // 	margin-right: $cta-slogan--padding-h__desktop;
    // }
  }
}

.form-one-level-up {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 40vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
}

.contact-form-div {
  margin-right: 0px;
}

.email-form-message {
  height: 200px;
  width: 100%;
}
