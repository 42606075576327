.features-tiles-item-image {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	width: 70px;
	border-radius: 50%;
	background-color: white;
	.skill-image {
		font-size: 50px;
	}
}

