.hero {
  .section-inner {
    padding-top: $hero--padding-t__mobile;
    padding-bottom: $hero--padding-b__mobile;
  }
}

.hero-inner {
  // Affects only hero full
  > .hero-content + .hero-figure,
  > .hero-figure + .hero-content {
    margin-top: $hero--inner-padding-v_mobile;
  }

  > .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

@include media("<=medium") {
  .hero {
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }
  // Added here to flex to column when viewing on iphone
  .hero-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    min-height: 75vh;
  }
}

@include media(">medium") {
  .hero {
    .section-inner {
      padding-top: $hero--padding-t__desktop;
      padding-bottom: $hero--padding-b__desktop;
    }
  }
  // Added here to flex to row when viewing on computer
  .hero-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    min-height: 75vh;

    // Affects only hero full
    > .hero-content + .hero-figure,
    > .hero-figure + .hero-content {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }
}

.left-side {
  flex: 1;
  .profile-picture {
    margin-bottom: 10px;
    height: 500px;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}

.right-side {
  flex: 2;
  .hero-qoute-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 10px;
    border: 3px solid #94d1be;
    border-radius: 5px;
    .hero-qoute {
      margin: 10px;
    }
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
